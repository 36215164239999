<template>
      <div :class="{'form-container' : showGrayBg}">
        <div :class="{'form-container__in' : showGrayBg}">
          <h4 v-if="showGrayBg">{{ $t('title') }}</h4>
          <Alert v-if="neoworker
                    && assignment
                    && assignment.uuid
                    && !assignment.fully_accepted
                    && !assignment.worker_agreed_at"
                    type="danger" class="alert-sm">
                      <small>{{ assignment.uuid ? assignment.neoworker.name ? assignment.neoworker.name : neoworker.job_title : neoworker.userActiveResume.job_title }} {{ $t('not-agreed') }}</small>
          </Alert>
          <Alert v-if="!neoworker
                    && assignment
                    && assignment.uuid
                    && !assignment.fully_accepted
                    && !assignment.client_agreed_at"
                    type="danger" class="alert-sm">
                      <small>{{ assignment.client.company.name }} {{ $t('not-agreed') }}</small>
          </Alert>
          <Alert v-if="neoworker
                    && assignment
                    && assignment.uuid
                    && !assignment.fully_accepted
                    && assignment.worker_agreed_at"
                    type="success" class="alert-sm">
                      <small>{{ assignment.uuid ? assignment.neoworker.name ? assignment.neoworker.name : neoworker.job_title : neoworker.userActiveResume.job_title }} {{ $t('agreed') }}</small>
          </Alert>
          <Alert v-if="!neoworker
                    && assignment
                    && assignment.uuid
                    && !assignment.fully_accepted
                    && assignment.client_agreed_at"
                    type="success" class="alert-sm">
                      <small>{{ assignment.client.company.name }} {{ $t('agreed') }}</small>
          </Alert>
          <Alert v-if="assignment.fully_accepted" type="success" class="alert-sm"> {{$t('both-agreed')}}</Alert>
          <AppInput
              v-if="neoworker"
              class="mb-2"
              :value="neoworker.name ? neoworker.name : neoworker.job_title ? neoworker.job_title : neoworker.userActiveResume.job_title"
              type="text"
              :label="$t('neoworker')"
              id="neoworker"
              :disabled="true"
          />
          <div class="row">
            <div class="col-md-4 col-xs-12 dates">
              <AppDatepicker
                  class="a-form-group w-100 input-white"
                  v-model="assignment.start_date"
                  :label="$t('start-date')"
                  :labelClass="true"
                  field="start_date"
                  :errors="errors"
                  :disabled="assignment.worker_agreed_at || userProfile.role.name === 'neo-worker'"
                  @change="validateDates"
              />
            </div>
            <div class="col-md-4 col-xs-12 dates">
              <AppDatepicker
                  class="a-form-group w-100 input-white"
                  v-model="assignment.end_date"
                  :label="$t('end-date')"
                  :labelClass="true"
                  field="end_date"
                  :errors="errors"
                  :disabled="assignment.worker_agreed_at || userProfile.role.name === 'neo-worker'"
                  @change="validateDates"
              />
            </div>
            <div class="col-md-4 col-xs-12 rate-chf-hour">
              <AppInput
                  v-model="assignment.hourly_rate"
                  :label="$t('rate-chf-label')"
                  type="number"
                  step="0.01"
                  :min="neoworker && neoworker.userDetails ? neoworker.userDetails.hourly_rate : 0"
                  field="hourly_rate"
                  :errors="errors"
                  :disabled="assignment.worker_agreed_at || userProfile.role.name === 'neo-worker'"
                  @input="reCalculate"
              />
              <div class="form-group">
                <label>&nbsp;</label>
                <div class="currency">
                  {{ $t('rate-desc') }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isMobile" class="mb-3 col-md-8 pl-0 zi-1">
            <DaysInWeek :desired_availability="assignment.desired_availability"
                        :errors="errors"
                        field="desired_availability"
                        :disabled="assignment.worker_agreed_at || userProfile.role.name === 'neo-worker'"
                        @change="reCalculate"
            />
          </div>
          <div class="mb-3 col-md-8 pl-0 zi-1" v-else>
            <DaysInWeekMobile :desired_availability="assignment.desired_availability"
                        :errors="errors"
                        field="desired_availability"
                        :disabled="assignment.worker_agreed_at || userProfile.role.name === 'neo-worker'"
                        @change="reCalculate"
            />
          </div>
          <AppTextarea
              class="mb-3 label-100"
              v-model="assignment.additional_agreements"
              :label="$t('additional-agr')"
              :placeholder="$t('please-additional-agr')"
              field="additional_agreements"
              className="additional-agreements"
              :errors="errors"
              :disabled="assignment.worker_agreed_at || userProfile.role.name === 'neo-worker'"
          />
        </div>
        <div class="row">
          <div class="col-md-7 col-xs-12 mw-100" :class="{'col-md-12': userProfile.role.name === 'neo-worker'}">
            <div class="row">
              <div class="col-xs-5 rate-chf-days" :class="{'col-md-3': userProfile.role.name === 'neo-worker', 'col-md-4': userProfile.role.name != 'neo-worker'}">
                <AppInput
                    v-model="assignment.days"
                    :label="$t('days')"
                    type="text"
                    field="days"
                    :disabled="true"
                    v-tooltip.hover="$t('estimated')"
                    :errors="errors"
                    infoIcon="true"
                />
              </div>
              <div class="col-xs-7 rate-chf-hour" :class="{'col-md-4': userProfile.role.name === 'neo-worker', 'col-md-8': userProfile.role.name != 'neo-worker'}">
                <AppInput
                    v-model="assignment.cost"
                    :label="userProfile.role.name === 'neo-worker' ? $t('cost') : $t('cost-client')"
                    type="text"
                    field="cost"
                    :disabled="true"
                    v-tooltip.hover="$t('estimated')"
                    infoIcon="true"
                />
                <div class="form-group">
                  <label>&nbsp;</label>
                  <div class="currency">
                    {{ currency }}
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-xs-12" v-if="userProfile.role.name === 'neo-worker'">
                <div class="rate-chf-hour">
                    <AppInput
                        v-model="assignmentCredits"
                        :label="$t('neoworq-credits')"
                        type="text"
                        field="neoworq-credits"
                        :disabled="true"
                        v-tooltip.hover="$t('neoworq-credits-tooltip')"
                        infoIcon="true"
                    />
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <div class="currency">
                        {{ currency }}
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-md-5 col-xs-12" :class="{'col-md-12 text-center': userProfile.role.name === 'neo-worker'}" v-if="assignment
                                      && assignment.uuid
                                      && assignment.chat_room
                                      && ( ( userProfile.role.name === 'neo-worker' && !assignment.worker_agreed_at)
                                        || ( (userProfile.role.name === 'neo-client' || userProfile.role.name === 'neo-client-hr' ) && !assignment.client_agreed_at))
                                      ">
            <SwipeButton class="mt-1" :class="{'pull-right': userProfile.role.name !== 'neo-worker'}" @agreed="isAgreed" />
          </div>
        </div>
        <span class="help-block" v-if="typeof errors !== 'undefined' && errors.has('project_role_uuid')">
          <p class="help-block__error text-danger" v-for="error in errors.get('project_role_uuid')" :key="error">
            <DangerIcon/> <small>{{ error }}</small>
          </p>
        </span>
      </div>
</template>

<script>
import { mapState } from 'vuex';
import { Alert, tooltip } from 'uiv';
import AppInput from '@/components/forms/AppInput';
import AppDatepicker from '@/components/forms/AppDatepicker';
import DaysInWeek from '@/components/forms/DaysInWeek';
import AppTextarea from '@/components/forms/AppTextarea';
import SwipeButton from '@/components/forms/SwipeButton';
import DangerIcon from '@/components//icons/DangerIcon';
import { mobileAndTabletCheck } from '../../../utils/helpers';
import DaysInWeekMobile from '@/components/forms/DaysInWeekMobile';
import { formatNumber } from '../../../utils/number.filter';

export default {
  name: 'assignment-template',
  i18nOptions: { namespaces: 'assignment-send-form' },
  components: {
    Alert,
    AppInput,
    AppDatepicker,
    DaysInWeek,
    AppTextarea,
    SwipeButton,
    DangerIcon,
    DaysInWeekMobile,
  },
  directives: {
    tooltip,
  },
  filters: {
    formatNumber: formatNumber,
  },
  props: ['neoworker', 'userProfile', 'assignment', 'errors', 'showGrayBg'],
  computed: {
    ...mapState({
      role: state => state.roles.role,
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    assignmentCredits() {
      if (this.assignment.cost) {
        const cost = this.assignment.cost.replace('’', '');
        return formatNumber(parseFloat(cost) * 0.25);
      }
      return '';
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    assignment: function (newVal, oldVal) {
      // console.log('assignment: ', newVal, ' | was: ', oldVal);
      if (newVal
          && oldVal
          && (
            (newVal.start_date !== oldVal.start_date)
            || (newVal.end_date !== oldVal.end_date)
            || (newVal.hourly_rate !== oldVal.hourly_rate)
            || (newVal.cost !== oldVal.cost)
            || (JSON.stringify(newVal.desired_availability) != JSON.stringify(oldVal.desired_availability)
            )
          )) {
        this.reCalculate();
      }
    },
    role: function (newVal, oldVal) {
      if (newVal && this.assignment && !this.assignment.uuid) {
        this.prefillAssignment();
      }
    },
  },
  data() {
    return {
      loading: false,
      currency: this.$appConfig.currency,
      isMobile: mobileAndTabletCheck(),
    };
  },
  methods: {
    prefillAssignment() {
      if (this.role.uuid && !this.assignment.uuid) {
        this.$store.dispatch('search/getUser', this.assignment.neoworker_uuid).then((res) => {
          const payload = {
            start_date: this.role.start_date,
            end_date: this.role.end_date,
            desired_availability: this.role.desired_availability,
            hourly_rate: this.role.hourly_rate_from > res.userDetails.hourly_rate ? this.role.hourly_rate_from : res.userDetails.hourly_rate,
          };
          this.updateProps(payload);
        });
      }
    },
    isAgreed() {
      this.$emit('agreed', 1);
    },
    validateDates() {
      this.reCalculate();
      this.errors.clear();
      const date1 = new Date(this.assignment.start_date);
      const date2 = new Date(this.assignment.end_date);
      date1.setHours(0, 0, 0, 0);
      date2.setHours(23, 59, 59, 999);
      if (date1 && date2 && date2.getTime() < date1.getTime()) {
        this.errors.set({ end_date: { 0: this.$t('dates-validation-error') } });
      }
    },
    reCalculate() {
      setTimeout(() => {
        const date1 = new Date(this.assignment.start_date);
        const date2 = new Date(this.assignment.end_date);
        date1.setHours(0, 0, 0, 0);
        date2.setHours(23, 59, 59, 999);

        const diffTime = date2.getTime() - date1.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

        const selectedDays = this.assignment.desired_availability.length;
        let totalDays = 0;

        if (diffDays < 7) {
          this.errors.clear();
          if (diffDays < (selectedDays / 2)) {
            this.errors.set({ days: { 0: this.$t('desired-availability-validation-error') } });
            totalDays = 0;
          } else {
            totalDays = selectedDays / 2;
          }
        } else {
          totalDays = Math.ceil((diffDays / 7 * 58.8 * (selectedDays / 14) / 8.4) * 2) / 2;
        }

        const payload = {
          days: totalDays,
          cost: formatNumber(totalDays * this.assignment.hourly_rate * 8.4),
        };

        this.updateProps(payload);
      }, 100);
    },
    updateProps(props) {
      this.$store.commit('assignment/assignmentProps', props);
    },
  },
};
</script>
