<template>
  <div class="assignment-project mb-3">
    <div v-if="isActive === true">
      <div class="content-text">
        <div class="content-text__title pb-2">
          <div class="content-text__title-group pb-1">
            <SendIcon />
            <div class="title">{{ $t('send-title') }}</div>
          </div>
          <div class="text">{{ $t('send-title-text') }}</div>
        </div>
      </div>
      <div v-if="isCreateAssignmentFormIsOpen === false" class="content-inputs">
        <div class="content-inputs__row">
          <button @click="openAssignmentForm" class="btn btn-block btn-primary-light">{{ $t('create') }}</button>
        </div>
      </div>
      <div v-if="isCreateAssignmentFormIsOpen === true" class="content-inputs__form">
        <div class="send-assignment-content">
          <form
              class="send-assignment-content__form"
              enctype="multipart/form-data"
              novalidate>
              <AssignmentTemplate v-if="assignment"
                                  :assignment="assignment"
                                  :neoworker="neoworker"
                                  :userProfile="userProfile"
                                  :errors="errors"
                                  showGrayBg="1"
                                  ref="assignmentTemplate"/>
              <div class="form-btn mt-4">
                <button @click="createAssignment" :disabled="loading" type="button" class="btn btn-block btn-primary-light btn-modal" >
                  {{ $t("send") }}
                </button>
                <button @click="cancelSendAssignment" type="button" class="btn btn-block btn-primary btn-modal" >
                  {{ $t("cancel") }}
                </button>
              </div>
            </form>
          </div>
      </div>
    </div>
    <div v-else>
      <div class="content-text inactive">
        <div class="content-text__title pb-2">
          <div class="content-text__title-group pb-1">
            <SendIconInactive />
            <div class="title">{{ $t('send-title') }}</div>
          </div>
          <div class="text">{{ $t('send-title-text') }}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import SendIcon from '../../icons/sending-an-assignment/SendIcon';
import SendIconInactive from '../../icons/sending-an-assignment/SendIconInactive';
import AssignmentTemplate from './AssignmentTemplate';
import Notifications from '@/services/notifications.service';
import Errors from '@/utils/errors';

export default {
  name: 'assignment',
  i18nOptions: { namespaces: 'assignment-content-send' },
  components: {
    SendIcon,
    SendIconInactive,
    AssignmentTemplate,
  },
  data() {
    return {
      loading: false,
      errors: new Errors(),
    };
  },
  props: ['assignment', 'isActive'],
  computed: {
    ...mapState({
      neoworker: state => state.search.user,
      userProfile: state => state.profile.profile,
      isCreateAssignmentFormIsOpen: state => state.assignment.isCreateAssignmentFormIsOpen,
    }),
  },
  mounted() {
    this.$store.dispatch('weekDays/getWeekDays');
  },
  methods: {
    openAssignmentForm() {
      this.$store.commit('assignment/isCreateAssignmentFormIsOpen', true);
    },
    success(res) {
      this.$store.commit('roles/resetRole');
      this.$store.commit('assignment/resetAssignment');
      this.$store.commit('assignment/isCreateAssignmentFormIsOpen', false);
      this.$router.push('/search');
      Notifications.notify('success', this.$t('аssignment-has-been-send'));
    },
    createAssignment() {
      this.loading = true;
      this.$store.dispatch('assignment/createAssignment')
        .then(res => this.success(res))
        .catch(err => this.errors.set(err.response.data.errors))
        .finally(() => {
          this.loading = false;
        });
    },
    cancelSendAssignment() {
      this.$store.commit('roles/resetRole');
      this.$store.commit('assignment/resetAssignment');
      this.$store.commit('assignment/isCreateAssignmentFormIsOpen', false);
      this.$store.dispatch('search/resetState');
      this.$router.push('/search');
    },
  },
};
</script>
