<template>
  <div :class="assignment.isActiveRole === true ? 'assignment-project mb-3 green-border' : 'assignment-project mb-3'">
    <div v-if="isActive === true">
      <GreenCheckIcon v-if="assignment.isActiveRole === true" />
      <div class="content-text">
        <div class="content-text__title pb-2">
          <div class="content-text__title-group pb-1">
            <RoleIcon />
            <div class="title">{{ $t('role-title') }}</div>
          </div>
          <div class="text">{{ $t('role-title-text') }}</div>
        </div>
      </div>
      <div class="content-inputs">
        <div v-if="assignment.isActiveRole === false" class="content-inputs__row" :key="keyValue">
          <AppSelect
              v-model="assignment.role_name"
              :options="roles ? roles : []"
              field="role_name"
              labelField="job_title"
              :placeholder="$t('select-role')"
              @input="addFromRoles()"
          />
          <button @click="openCreateRoleForm" class="btn btn-block btn-primary-light">{{ $t('create') }}</button>
        </div>
        <div v-else class="direction-row content-inputs__row">
          <span v-if="assignment.role_name.job_title" class="project-name">{{ assignment.role_name.job_title }}</span>
          <button class="btn btn-link cancel-select" @click="cancelSelectRole">
            <StepBackIcon />
          </button>
        </div>
        <div v-if="isCreatedRoleFormIsOpen === true" class="content-inputs__form">
          <div class="form-container">
            <div class="w-100">
              <h4>{{ $t('title-add-role') }}</h4>

              <div class="w-100">
                <form autocomplete="off" class="pt-2" novalidate>
                  <RoleTemplateAssignment
                      :role="role"
                      :roleMinDate="roleMinDate"
                      :dateLimitTo="dateLimitTo"
                      :monthlyRateFrom="monthlyRateFrom"
                      :monthlyRateTo="monthlyRateTo"
                      :errors="errors"
                      @reCalculate="reCalculate"
                  />
                </form>
              </div>

            </div>
          </div>
          <div class="form-btn mt-4">
            <button @click="createRole" type="button" class="btn btn-block btn-primary-light btn-modal mt-4" >
              {{ $t("save") }}
            </button>
            <button @click="cancelCreateRole" type="button" class="btn btn-block btn-primary btn-modal" >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="content-text inactive">
        <div class="content-text__title pb-2">
          <div class="content-text__title-group pb-1">
            <RoleIconInactive />
            <div class="title">{{ $t('role-title') }}</div>
          </div>
          <div class="text">{{ $t('role-title-text') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RoleIcon from '@/components/icons/sending-an-assignment/RoleIcon';
import RoleIconInactive from '@/components/icons/sending-an-assignment/RoleIconInactive';
import StepBackIcon from '@/components/icons/sending-an-assignment/StepBackIcon';
import GreenCheckIcon from '@/components/icons/sending-an-assignment/GreenCheckIcon';
import RoleTemplateAssignment from '@/components/profile-company/RoleTemplateAssignment';
import AppSelect from '@/components/forms/AppSelect';
import Notifications from '@/services/notifications.service';
import Errors from '@/utils/errors';

export default {
  name: 'assignment-content-role',
  i18nOptions: { namespaces: 'assignment-content-role' },
  components: {
    RoleIcon,
    RoleIconInactive,
    AppSelect,
    GreenCheckIcon,
    StepBackIcon,
    RoleTemplateAssignment,
  },
  data() {
    return {
      errors: new Errors(),
      keyValue: 0,
      monthlyRateFrom: null,
      monthlyRateTo: null,
    };
  },
  props: ['isActive'],
  computed: {
    ...mapState({
      roles: state => state.roles.roles,
      assignment: state => state.assignment.assignment,
      projectId: state => state.projects.activeProjectId,
      isCreatedRoleFormIsOpen: state => state.assignment.isCreatedRoleFormIsOpen,
      roleMinDate: state => state.roles.roleMinDate,
      roleMaxDate: state => state.roles.roleMaxDate,
      dateLimitTo: state => state.roles.dateLimitTo,
      role: state => state.roles.role,
    }),
  },
  mounted() {
    this.$store.dispatch('roles/getProjectRoles', this.assignment.project_uuid);
  },
  watch: {
    roleMinDate(newVal, oldVal) {
      if (!this.role.uuid) {
        this.role.start_date = newVal;
      }
      // console.log('roleMinDate changed: ', newVal, ' | was: ', oldVal);
    },
    roleMaxDate(newVal, oldVal) {
      if (!this.role.uuid) {
        this.role.end_date = newVal;
      }
      // console.log('roleMaxDate changed: ', newVal, ' | was: ', oldVal);
    },
  },
  methods: {
    addFromRoles(roleUuid = '') {
      const payload = {
        project_role_uuid: roleUuid || this.assignment.role_name.uuid,
        isActiveRole: true,
      };
      const selectedRole = this.roles.filter(x => x.uuid === payload.project_role_uuid);
      this.$store.commit('roles/role', selectedRole[0]);
      this.$store.commit('assignment/assignmentProps', payload);
      this.$store.commit('assignment/isCreatedRoleFormIsOpen', false);
    },
    updateProps(props) {
      this.$store.commit('roles/roleProps', props);
    },
    createRole() {
      this.loading = true;

      this.updateProps({ project_uuid: this.projectId });

      this.$store
        .dispatch('roles/createRole')
        .then((res) => {
          const newRole = res.data;
          this.errors.clear();
          this.cancelCreateRole();
          Notifications.notify('success', 'Role added');
          this.$store.dispatch('roles/getProjectRoles', this.projectId).then(() => {
            this.$store.commit('assignment/assignmentProps', { role_name: newRole });
            this.addFromRoles(newRole.uuid);
          });
          this.keyValue += 1;
        })
        .catch((err) => {
          if (err.hasOwnProperty('response')) {
            this.errors.set(err.response.data.errors);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openCreateRoleForm() {
      this.$store.commit('roles/prefillRoleDesiredAvailability');
      this.$store.commit('assignment/isCreatedRoleFormIsOpen', true);
    },
    cancelCreateRole() {
      // this.$store.dispatch('roles/resetState');
      this.$store.commit('roles/resetRole');
      this.$store.commit('assignment/isCreatedRoleFormIsOpen', false);
      this.clearCalculations();
    },
    cancelSelectRole() {
      const payload = {
        role_name: '',
        project_role_uuid: '',
        desired_availability: [],
        isActiveRole: false,
      };
      this.$store.commit('assignment/assignmentProps', payload);
      this.$store.commit('roles/resetRole');
      this.clearCalculations();
    },
    reCalculate() {
      this.calculateRateFrom();
      this.calculateRateTo();
    },
    calculateRateFrom() {
      const rateFrom = this.role.hourly_rate_from;
      this.monthlyRateFrom = rateFrom ? 182.25 * rateFrom : null;
    },
    calculateRateTo() {
      const rateTo = this.role.hourly_rate_to;
      this.monthlyRateTo = rateTo ? 182.25 * rateTo : null;
    },
    clearCalculations() {
      this.monthlyRateTo = null;
      this.monthlyRateFrom = null;
    },
  },
};
</script>
