<template>
  <transition name="fade" mode="out-in">
    <div class="dashboard-page direction-column">
      <div class="sending-an-assignment">
        <div class="sending-an-assignment__content">
          <div class="sending-an-assignment__heading pb-3">
            <h3>{{ $t("explanation") }}</h3>
            <div class="explanation-content">{{ $t('explanation-content') }}</div>
          </div>
          <div class="sending-an-assignment__body pb-1">
            <AssignmentProject />
            <AssignmentRole :isActive="assignment.isActiveProject" />
            <Assignment :assignment="assignment" :isActive="assignment.isActiveRole" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import AssignmentProject from '@/components/company/sending-an-assignment/AssignmentProject';
import AssignmentRole from '@/components/company/sending-an-assignment/AssignmentRole';
import Assignment from '@/components/company/sending-an-assignment/Assignment';
import router from '@/config/router.config';

export default {
  name: 'sending-an-assignment',
  i18nOptions: { namespaces: 'assignment-content' },
  components: {
    AssignmentProject,
    AssignmentRole,
    Assignment,
  },
  computed: {
    ...mapState({
      assignment: state => state.assignment.assignment,
    }),
  },
  mounted() {
    this.checkUuid();
  },
  methods: {
    checkUuid() {
      if (this.assignment.neoworker_uuid === null) {
        router.push('/search');
      }
    },
  },
};
</script>
