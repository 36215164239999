<template>
  <div class="btn__swipe">
    <div class="btn__swipe__range">
      <div class="btn__swipe__fake">>></div>
      <input type="range" class="btn__swipe__pullee" :name="name"/>
      <div class="btn__swipe__literal">
        <span class="literal">{{ $t('agree-to-contract') }}</span>
      </div>
    </div>
    <div class="btn__swipe__success">{{ $t('agreed') }}</div>
  </div>
</template>

<script>
export default {
  i18nOptions: { namespaces: 'translation' },
  props: ['value', 'name'],
  data() {
    return {
      inputRange: null,
      fake: null,
      label: null,
      maxValue: 100,
      speed: 12,
      currValue: null,
      rafID: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.inputRange = document.getElementsByClassName('btn__swipe__pullee')[0];
      this.inputRange.min = 0;
      this.inputRange.max = this.maxValue;
      this.inputRange.value = this.value ? this.value : 0;

      // bind events
      this.animateFake();
      this.inputRange.addEventListener('mousedown', this.unlockStartHandler, false);
      this.inputRange.addEventListener('mousestart', this.unlockStartHandler, false);
      this.inputRange.addEventListener('mouseup', this.unlockEndHandler, false);
      this.inputRange.addEventListener('touchend', this.unlockEndHandler, false);
      this.inputRange.addEventListener('input', this.animateFake);
    },
    // listen for unlock
    unlockStartHandler() {
      // clear raf if trying again
      window.cancelAnimationFrame(this.rafID);
      // set to desired value
      this.currValue = +this.value;
      this.animateFake();
    },
    unlockEndHandler() {
      // store current value
      this.currValue = +this.inputRange.value;
      // determine if we have reached success or not
      if (this.currValue >= this.maxValue) {
        this.successHandler();
      } else {
        this.rafID = window.requestAnimationFrame(this.animateHandler);
      }
      this.animateFake();
    },
    animateHandler() {
      // update input range
      this.inputRange.value = this.currValue;

      // determine if we need to continue
      if (this.currValue > -1) {
        window.requestAnimationFrame(this.animateHandler);
      }

      // decrement value
      this.currValue = this.currValue - this.speed;
      this.animateFake();
    },
    // handle successful unlock
    successHandler() {
      // reset input range
      this.inputRange.value = 0;
      this.inputRange.closest('.btn__swipe').classList.add('success');
      this.$emit('agreed', 1);
    },
    animateFake() {
      this.fake = document.querySelector('.btn__swipe__fake');
      this.label = document.querySelector('.btn__swipe__literal .literal');
      const value = this.inputRange.value;
      const valueMax = 100;
      const valueMin = 0;
      const totalInputWidth = 254;
      const thumbHalfWidth = 49 / 2;
      const ratio = (value - valueMin) / (valueMax - valueMin);
      const left = (((value - valueMin) / (valueMax - valueMin)) * ((totalInputWidth - thumbHalfWidth) - thumbHalfWidth)) + thumbHalfWidth;

      this.fake.style.left = `${left}px`;
      // fake.style.transform = `translateX(-50%) rotate(${360*ratio}deg)`;
      this.label.style.opacity = (1 - ratio);
    },
  },
};
</script>
